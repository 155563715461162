@import '../load.scss';

.contentHeader {
    width: 100%;
    display: block;
    .contentHeaderInner {
        padding: 1rem;
        border-bottom: 1px solid $border-gray;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .contentHeaderCol {
            h1 {
                @include text-overflow();
                font-size: 1.25rem;
                font-weight: 500;
                color: $text-dark;
                margin: 0;
            }
            h2 {
                @include text-overflow();
                font-size: 1rem;
                font-weight: 300;
                color: $text-dark;
                margin: 0;
            }
            a, button {
                @include remove-webkit();
                background-color: $main-color;
                color: white;
                font-weight: 300;
                font-size: 1rem;
                text-decoration: none;
                padding: .75rem 1rem;
            }
        }
    }
}
@import '../load.scss';

.optionsList {
    display: block;
    position: relative;
    .optionsListOptions {
        top: 1.5rem;
        left: 0;
        position: absolute;
        background-color: white;
        border: 1px solid $border-gray;
        z-index: 5;
        a, button {
            @include remove-webkit();
            color: $text-dark;
            font-size: .75rem;
            font-weight: 500;
            padding: .25rem .75rem;
            padding-left: .5rem;
            border-bottom: 1px solid $border-gray;
            cursor: pointer;
            display: block;
            &:hover {
                color: $main-color;
            }
        }
    }
    .toggleButton {
        @include remove-webkit();
        width: 1.75rem;
        height: 1.25rem;
        border: 1px solid $border-gray;
        border-radius: 2px;
        border-bottom-width: 2px;
        background-image: url('../../images/toggleButton.svg');
        background-size: 1rem;
        background-position: center center;
        background-repeat: no-repeat;
        cursor: pointer;
    }
}
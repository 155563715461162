$desktop-width: 1024px;
$tablet-width: 768px;
$mobile-width: 768px;

// Remove webkit
// * Used to reset the default webkit appearance.
@mixin remove-webkit() {
    -webkit-appearance: none;
    border:0;
    outline: 0;
}

// Text overflow
// * Used to handle the text overflow.
@mixin text-overflow($type: ellipsis) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

// Tablet
// * Used to run media queries on tablets.
@mixin tablet {
    @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
      @content;
    }
}

// Mobile
// * Used to run media queries on mobile.
@mixin mobile {
    @media (max-width: #{$mobile-width}) {
        @content;
    }
}
@import '../load.scss';

html {
  font-size: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  line-height: 1.5;
}

button:disabled {
  opacity: 0.5;
}

main {
  width: calc(100% - 300px);
  min-height: 100vh;
  display: inline-block;
  vertical-align: top;
  &.full-width {
    width: 100%;
  }
}

.main-content {
  width: 100%;
  padding: 1rem;
  &.forms {
    margin: .5rem;
    width: 35%;
    input, textarea, select, button {
      @include remove-webkit();
      width: 100%;
      border: 1px solid $border-gray;
      background-color: $base-gray;
      padding: .75rem;
      margin-bottom: .5rem;
      display: block;
    }
    button {
      color: $main-color;
      text-align: left;
    }
    .image-preview {
      width: 100%;
      padding-top: 50%;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      margin-bottom: .5rem;
    }
    span.uploading-image {
      padding: .75rem;
      margin: .5rem 0;
      background-color: $main-color;
      color: white;
      display: block;
    }
  }
}

label {
  color: $text-gray;
  font-size: .9rem;
  text-transform: capitalize;
  display: block;
  margin-bottom: .5rem;
}
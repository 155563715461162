@import '../load.scss';

.tableData {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    text-align: left;
    tr.tableHeader {
        border-bottom: 1px solid $border-gray;
        th { 
            font-size: 1rem;
            font-weight: 500;
            color: $text-gray;
            padding: .75rem 0;
        }
    }
    tr {
        border-bottom: 1px solid $border-gray;
        a {
            text-decoration: none;
        }
        td {
            font-size: 1rem;
            font-weight: 300;
            padding: .75rem 0;
            color: $text-dark;
            a {
                color: $text-dark;
            }
        }
    }
}
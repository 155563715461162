@import '../load.scss';

.header {
    width: 300px;
    min-height: 100vh;
    border-right: 1px solid $base-gray;
    display: inline-block;
    position: relative;
    .headerTop {
        border-bottom: 1px solid $border-gray;
        padding: 1rem;
        display: block;
        h1 {
            font-size: 1.25rem;
            font-weight: 500;
            color: $main-color;
            margin: 0;
        }
        h2 {
            font-size: 1rem;
            font-weight: 300;
            color: $text-dark;
            margin: 0;
        }
    }
    .headerInner {
        padding: 1rem;
        display: block;
        ul.linksList {
            padding: 0;
            margin: 0;
            list-style: none;
            li {
                border-bottom: 1px solid $border-gray;
                padding: .5rem 0;
                display: block;
                &:first-child {
                    padding-top: 0;
                }
                &:last-child {
                    border-bottom: 0;
                }
                a {
                    font-size: 1rem;
                    font-weight: 300;
                    color: $text-dark;
                    text-decoration: none;
                    display: block;
                    &:hover {
                        color: $main-color;
                    }
                }
            }
        }
    }
    .headerBottom {
        position: absolute;
        left: 1rem;
        right: 1rem;
        bottom: 1rem;
        display: block;
        border-top: 1px solid $border-gray;
        padding-top: 1rem;
        button {
            @include remove-webkit();
            width: 100%;
            font-size: 1rem;
            font-weight: 300;
            color: $text-dark;
            text-align: left;
            padding: 0;
            &:hover {
                cursor: pointer;
                color: $main-color;
            }
        }
    }
}
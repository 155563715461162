@import '../load.scss';

.hairstylistImage {
    width: 3rem;
    height: 3rem;
    background-color: whitesmoke;
    margin-top: .5rem;
    margin-bottom: .5rem;
    border-radius: 2px;
    margin-right: 1rem;
    background-size: cover;
    background-position: center center;
}
// Base colors
$base-white: white;
$base-gray: #f5f5f5;

// Text
$text-dark: #404040;
$text-gray: gray;

// Borders
$border-gray: #f0efed;

// Main color (branding)
$main-color: #6a5acd;
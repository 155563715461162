@import '../load.scss';

.loginContainer {
    width: 300px;
    min-height: 300px;
    display: block;
    margin: 0 auto;
    margin-top: 150px;
    h1 {
        font-size: .9rem;
        margin: .75rem 0;
        display: block;
        text-align: center;
        color: $text-dark;
        font-weight: 400;
    }
    input, button {
        @include remove-webkit();
        width: 100%;
        display: block;
        border: 1px solid $border-gray;
        text-align: left;
        padding: .75rem;
        font-size: 1rem;
        font-weight: 400;
        color: $text-dark;
        margin-top: .75rem;
        &:first-child {
            margin-top: 0;
        }
    }
    button {
        color: $main-color;
        background-color: rgba($main-color, .1);
    }
}